export const SiteList = [
  {
    title: 'カオスちゃんねる',
    link: 'http://chaos2ch.com',
  },
  {
    title: 'VIPPERな俺',
    link: 'http://blog.livedoor.jp/news23vip',
  },
  {
    title: '【2ch】ニュー速クオリティ',
    link: 'http://news4vip.livedoor.biz',
  },
  {
    title: 'キニ速',
    link: 'http://blog.livedoor.jp/kinisoku',
  },
  {
    title: '暇人＼(^o^)／速報',
    link: 'http://himasoku.com',
  },
  {
    title: 'お料理速報',
    link: 'http://oryouri.2chblog.jp',
  },
  {
    title: 'ライフハックちゃんねる弐式',
    link: 'http://lifehack2ch.livedoor.biz',
  },
  {
    title: '2chコピペ保存道場',
    link: 'http://2chcopipe.com',
  },
  {
    title: 'ネギ速',
    link: 'http://www.negisoku.com',
  },
  {
    title: 'なんJワールド',
    link: 'http://nanj-world.blog.jp',
  },
  {
    title: 'なんJワールド',
    link: 'http://www.nandemo-uketori.com',
  },
  {
    title: 'なんでも受信遅報@なんJ・おんJまとめ',
    link: 'http://blog.livedoor.jp/gourmetmatome',
  },
  {
    title: '( ヽ´ん`)ケンモメン速報@774Netz',
    link: 'http://blog.livedoor.jp/createday',
  },
  {
    title: 'Jのログ＠おんJまとめブログ',
    link: 'http://mylife.2chblog.jp',
  },
];

<template>
  <div class="contents">
    <div class="contents-title">{{title}}</div>
    <template v-for="item in contents.filter((v) => !!v)" :key="item.link">
      <item :item="item" />
    </template>
  </div>
</template>

<script>
import Item from '@/components/ContentsList/Item'

export default {
  name: 'ContentsList',
  components: {
    Item,
  },
  props: {
    title: { type: String, required: true },
    contents: { type: Array, required: true },
  },
}
</script>

<style scoped>
.contents {
  width: 100%;
}
.contents-title {
  width: 100%;
  border-bottom: 1px solid;
}
</style>

<template>
  <div id="app">
    <loading
      v-model:active="isLoading"
    />
    <div class="app-title">2ch ランダムまとめ</div>
    <div class="app-contents">
      <div class="app-contents-random">
        <ContentsList
          title="ランダム記事"
          :contents="getRandomContents()"
        />
      </div>
      <div class="spacer" />
      <div class="app-contents-recent">
        <ContentsList
          title="最新記事"
          :contents="allContents"
        />
      </div>
      <div class="spacer" />
      <div class="app-contents-random">
        <ContentsList
          title="ランダム記事"
          :contents="getRandomContents()"
        />
      </div>
      <div class="spacer" />
      <Contact />
    </div>
  </div>
</template>

<script>
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
import ContentsList from '@/components/views/ContentsList.vue';
import Contact from '@/components/views/Contact.vue';
import { getRandomInt } from '@/utils/math_utils';
import axios from 'axios'
axios.defaults.headers = {
  'Cache-Control': 'no-cache',
  'Pragma': 'no-cache',
  'Expires': '0',
}

export default {
  name: 'App',
  components: {
    Loading,
    ContentsList,
    Contact,
  },
  data() {
    return {
      contents: [],
      isLoading: true,
    }
  },
  computed: {
    allContents() {
      return this.contents;
    },
  },
  beforeMount() {
    this.fetch();
  },
  methods: {
    async fetch() {
      const PAGE_NUM = 1;
      let response = []
      await Promise.all([...Array(PAGE_NUM)].map(async (_, index) => {
        const res = await axios.get(`/.netlify/functions/function?page=${index}`)
        response = response.concat(res.data);
      }));
      this.contents = this.sortByDate(response);
      this.isLoading = false;
    },
    sortByDate(articles) {
      return articles.sort((a, b) => b.d - a.d);
    },
    getRandomContents() {
      const MAX_CHOICE_ARTICLE_NUM = 10;
      const contents = this.contents.concat();
      const randomContents = []
      for (let i = 0; i < MAX_CHOICE_ARTICLE_NUM; ++i) {
        const targetIndex = getRandomInt(contents.length);
        randomContents.push(contents[targetIndex]);
        contents.splice(targetIndex, 1);
      }
      return randomContents;
    },
  },
}
</script>

<style scoped>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.app-title {
  font-size: 48px;
  padding: 20px;
  width: 70vw;
}
.app-contents {
  width: 70vw;
}
.spacer {
  padding: 10px;
}

@media screen and (max-width:540px) {
  .app-title {
    font-size: 32px;
    padding: 20px;
    width: 90vw;
  }
  .app-contents {
    width: 90vw;
  }
}
</style>

<template>
  <div class="content-item">
    <a class="content-item-link" :href="itemLink" target="blank">{{item.t}}</a>
    <div class="content-item-detail">
      <div class="content-item-detail-date">{{date}}</div>
      <a class="content-item-detail-site" :href="siteLink" target="blank">{{siteTitle}}</a>
    </div>
  </div>
</template>

<script>
import { SiteList } from '@/consts';

export default {
  name: 'ContentItem',
  props: {
    item: { type: Object, required: true },
  },
  computed: {
    itemLink() {
      return `${this.siteLink}/archives/${this.item.a}.html`
    },
    date() {
      const date = new Date(this.item.d);
      return date.toLocaleDateString() + " " + date.toLocaleTimeString()
    },
    siteLink() {
      return SiteList[this.item.i].link;
    },
    siteTitle() {
      return SiteList[this.item.i].title;
    },
  },
}
</script>

<style scoped>
.content-item {
  padding: 12px;
}

.content-item-link,
.content-item-detail-site {
  text-decoration: none;
}

.content-item-detail {
  display: flex;
  font-size: 14px;
  color: gray;
  padding-left: 10px;
  align-items: center;
}
.content-item-detail-site,
.content-item-detail-date {
  line-height: 18px;
}
.content-item-detail-site {
  color: gray;
  margin-left: 10px;
}

@media screen and (max-width:540px) {
  .content-item-detail {
    font-size: 12px;
  }
}
</style>

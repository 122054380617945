<template>
  <div class="contact">
    お問い合わせは<a href="mailto:heigen.application@gmail.com" class="mailto">こちら</a>
  </div>
</template>

<script>
export default {
  name: 'Contact'
}
</script>

<style scoped>
.contact {
  font-size: 12px;
}
.mailto {
  color: black;
  font-weight: bold;
}
</style>
